<template>
  <div>
    <div class="title-div">
      <b-button class="btn-return" size="sm" @click="returnToGradingVIew">
        Volver
      </b-button>
      <div class="text-center w-100">
        <h4 class="text-center">
          Ajuste de Calificaciones <strong>{{ evaluation.name }}</strong>
          <!-- Evaluar <strong>{{ evaluation.name }}</strong> -->
        </h4>
      </div>
    </div>
    <template v-if="evaluation">
      <EvaluationHeader
        :Evaluation="evaluation"
        :evaluation_type="getEvaluationType"
        :evaluation_category="getEvaluationCategory"
      >
      </EvaluationHeader>
    </template>
    <!-- v-if="section" -->
    <div
      class="student-carousel mt-4"
      :class="{ 'student-carousel-sticky': window_top > 318 }"
    >
      <div v-if="window_top > 318" class="current-student-label"
        ><strong> Actualmente evaluando a: </strong>
      </div>
      <template v-if="section_id">
        <StudentCarousel
          :section_id="section_id"
          :sticky="window_top > 318 ? true : false"
          @student_selcted="slotStudentSelected"
        ></StudentCarousel>
      </template>
      <template v-else>
        <StudentCarousel
          :current_users="current_users"
          :sticky="window_top > 318 ? true : false"
          @student_selcted="slotStudentSelected"
        ></StudentCarousel>
      </template>
    </div>
    <template>
      <div class="mt-2">
        <b-button
          class="toggle-evaluation-instruments mt-2"
          size="sm"
          v-b-toggle.collapse-a.collapse-question-tests
          v-if="questionTestsList.length > 0"
          variant="none"
          >Instrumentos Escritos
        </b-button>
        <b-collapse
          v-model="collapse1_visible"
          id="collapse-question-tests"
          v-if="questionTestsList.length > 0"
          class="mt-1"
        >
          <b-tabs v-model="tab_index_tests">
            <div
              class="empty-instrument-view"
              v-if="questionTestsList.length < 1"
            >
              No existen instrumentos escritos para esta evaluación.
            </div>
            <b-tab
              v-for="question_test in questionTestsList"
              :title="question_test.title"
              :key="question_test.id"
            >
              <div ref="question_tests" class="current-tab">
                <template>
                  <TestContainer
                    :question_test_id="question_test.id"
                    :student_id="selected_student_id"
                    :window_top="window_top"
                    :tab_index_tests="tab_index_tests"
                    :period_id="period_id"
                    @instrumentTabEvent="instrumentTabChange"
                  ></TestContainer>
                </template>
              </div>
            </b-tab>
            <!-- TODO: Habilitar scroll a la parte superior del instrumento al usar navegación. -->
            <b-button
              v-if="questionTestsList.length > 1"
              class="my-3 mr-1 px-3"
              size="sm"
              @click="navigateInstrument(0)"
              :disabled="tab_index_tests == 0"
              >Instrumento Anterior</b-button
            >
            <b-button
              v-if="questionTestsList.length > 1"
              class="my-3 ml-1 px-3"
              size="sm"
              @click="navigateInstrument(1)"
              :disabled="tab_index_tests == questionTestsList.length - 1"
              >Siguiente Instrumento</b-button
            >
            <!-- <b-button
              v-if="questionTestsList.length > 1"
              class="my-3 ml-1 px-3"
              size="sm"
              @click="tab_index_tests++"
              >Siguiente Instrumento</b-button
            > -->
          </b-tabs>
        </b-collapse>
      </div>
    </template>
    <template>
      <b-button
        class="toggle-evaluation-instruments mt-2"
        size="sm"
        v-b-toggle.collapse-a.collapse-observation-instruments
        v-if="rubricsList.length > 0"
        variant="none"
        >Instrumentos de Observación
      </b-button>
      <b-collapse
        v-model="collapse2_visible"
        id="collapse-observation-instruments"
        v-if="rubricsList.length > 0"
        class="mt-1"
      >
        <b-tabs v-model="tab_index_observation">
          <div class="empty-instrument-view" v-if="rubrics.length < 1">
            No existen instrumentos de observación para esta evaluación.
          </div>
          <b-tab
            v-for="observation_instrument in rubricsList"
            :title="observation_instrument.title"
            :key="observation_instrument.id"
          >
            <EvaluateRubric
              :observation_instrument_id="observation_instrument.id"
              :student_id="selected_student_id"
              :period_id="period_id"
            ></EvaluateRubric>
            <b-button
              v-if="rubricsList.length > 1"
              class="mt-2"
              size="sm"
              @click="tab_index_observation++"
              >Siguiente Instrumento</b-button
            >
          </b-tab>
        </b-tabs>
      </b-collapse>
    </template>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "EvaluateView",
  props: {
    question_test_id: {
      type: Number,
    },
    evaluation_id: {
      type: Number,
    },
    current_users: {
      type: Array,
    },
    section_id: {
      type: Number,
    },
    period_id: {
      type: Number,
    },
  },
  components: {
    // TestMaker: () => import("../TestMaker.vue"),
    EvaluationHeader: () => import("@/components/evaluations/EvaluationHeader"),
    TestContainer: () => import("./TestContainer"),
    StudentCarousel: () => import("@/components/reusable/StudentCarousel"),
    EvaluateRubric: () =>
      import("@/components/evaluations/ObservationInstrument/EvaluateRubric"),
  },
  data() {
    return {
      selected_student_id: null,
      tab_index_tests: 0,
      tab_index_observation: 0,
      collapse1_visible: true,
      collapse2_visible: true,
      evaluation_types: [],
      evaluation_categories: [],
      window_top: 0,
      current_tab_height: 0,
    };
  },
  computed: {
    ...mapGetters({
      questionTests: names.QUESTION_TESTS,
      evaluations: names.EVALUATIONS,
      sections: names.SECTIONS,
      rubrics: names.OBSERVATION_INSTRUMENTS,
      users: names.USERS,
    }),
    questionTestsList() {
      return this.questionTests
        .filter((x) => x.evaluation == this.evaluation_id)
        .sort(function (a, b) {
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
          return 0;
        });
    },
    rubricsList() {
      return this.rubrics
        .filter((x) => x.evaluation == this.evaluation_id)
        .sort(function (a, b) {
          if (a.title > b.title) return 1;
          if (a.title < b.title) return -1;
          return 0;
        });
    },
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    getEvaluationType() {
      if (!this.evaluation_types || !this.evaluation) return;
      const type_name = this.evaluation_types.find(
        (x) => x.id == this.evaluation.type
      );
      return type_name ? type_name.value : "";
    },
    getEvaluationCategory() {
      if (!this.evaluation_categories || !this.evaluation) return;
      const category_name = this.evaluation_categories.find(
        (x) => x.id == this.evaluation.category
      );
      return category_name ? category_name.value : "";
    },
    section() {
      if (!this.evaluation) return null;
      return this.sections.find((x) => x.id == this.evaluation.section);
    },
    students() {
      let list = [];
      if (!this.section) return list;
      this.section.students.forEach((student_id) => {
        const student = this.users.find((x) => x.id == student_id);
        if (student) list.push(student);
      });
      return list;
    },
  },
  methods: {
    slotStudentSelected(student_id) {
      this.selected_student_id = student_id;
    },
    returnToGradingVIew() {
      this.$emit("backToView");
    },
    fetchEvaluationTypes() {
      this.$restful.Get("/teaching/evaluation-types/").then((response) => {
        this.evaluation_types = response;
      });
    },
    fetchEvaluationCategories() {
      this.$restful.Get("/teaching/evaluation-categories/").then((response) => {
        this.evaluation_categories = response;
      });
    },
    fetchCanPublishAndEdit() {
      if (this.student_id) {
        this.$restful
          .Get(
            `/teaching/can_publish_and_edit_score/?evaluation=${this.evaluation_id}&student=${this.student_id}/`
          )
          .then((response) => {
            console.log(response);
          });
      }
    },
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
    instrumentTabChange(element_ref) {
      if (element_ref != 0) {
        this.current_tab_height = element_ref;
      }
    },
    getYPosition() {
      var top = window.pageYOffset || document.documentElement.scrollTop;
      return top;
    },
    navigateInstrument(next_instrument) {
      if (next_instrument == 0) {
        this.tab_index_tests--;
        setTimeout(() => {
          window.scrollTo(0, 452);
        }, 100);
      } else {
        this.tab_index_tests++;
        setTimeout(() => {
          window.scrollTo(0, 452);
        }, 100);
      }
    },
  },
  created() {
    this.fetchEvaluationTypes();
    this.fetchEvaluationCategories();
    this.$store.dispatch(names.FETCH_QUESTION_TESTS, this.evaluation_id);
    this.$store.dispatch(names.FETCH_QUESTION_TYPES);
    this.$store.dispatch(
      names.FETCH_OBSERVATION_INSTRUMENTS,
      this.evaluation_id
    );
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style scoped>
html {
  scroll-behavior: smooth;
}
.title-div {
  display: flex;
}
.btn-return {
  padding: 0.3em 1em;
  height: 2.3em;
}
.student-carousel {
  transition: 0.2s;
  display: flex;
}
.student-carousel-sticky {
  width: calc(100% + 20px);
  max-height: 62px;
  background: var(--primary-hover-color);
  box-shadow: 0 1px 7px -4px var(--secondary-color);
  padding-top: 1.5em;
  padding-inline: 1em;
  transition: 0.2s;
  position: fixed;
  top: 54px;
  left: 0;
  z-index: 300;
}
#collapse-observation-instruments {
  z-index: 1000 !important;
}
.current-tab {
  background: var(--primary-hover-color);
}
.current-student-label {
  /* transform: translateY(-30%); */
  margin-left: 0.5em;
  margin-right: 1em;
  /* width: calc(40% + 10px); */
  width: 250px;
}
.toggle-evaluation-instruments {
  width: 100%;
  background-color: var(--kl-secondary-button-color) !important;
  color: #fff;
  transition: all 0.3s;
}
.toggle-evaluation-instruments:hover {
  background-color: var(--kl-secondary-button-hover-color) !important;
}
</style>